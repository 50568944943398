export const callFormDimension = (dimension) => {
    let formWidth = 360;
    let formHeight = 640;
    
    if (formWidth >= dimension.width - 10) {
        formWidth = dimension.width - 10;
    }
    if (formHeight >= dimension.height - 10) {
        formHeight = dimension.height - 10
    }
    if (formWidth * 16 >= formHeight * 9) {
        formWidth = formHeight * 9 / 16;
    } else {
        formHeight = formWidth * 16 / 9;
    }
    return { formWidth: formWidth, formHeight: formHeight}
}