import { FlatList, Image, Text } from "react-native";
import { View } from "react-native";
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import { screens } from "../utils/ScreenUtils";
import { useState } from "react";
import { getInfos, getNextImgId, getPrevImgId, getProductImg, getStartImgId4Product } from "../data/manualData";
import { generateRandomString } from "../utils/Tool";
import { ROOT_FONT } from "../utils/Constants";

const ItemInfo = ({ id, idInfo, infos, width }) => {
    const [expansed, setExpansed] = useState(false);
    var info = infos[(id + idInfo) % infos.length];
    var fontSizeTitle = 16;
    var fontSizeDescription = 14;
    if (width <= 300) {
        fontSizeTitle = 12;
        fontSizeDescription = 11;
    }
    return (
        <View
            style={{
                width: width,
                marginVertical: 10,
                alignItems: "stretch",
                flexDirection: "column",
                marginBottom: "1rem",
                marginVertical: 10,
            }}
        >
            {expansed ?
                <>

                    <View
                        style={{
                            paddingLeft: ".75rem",
                            backgroundColor: "#A7D1F6",
                            borderColor: "#4C8EF6",
                            borderWidth: 2,
                            borderTopLeftRadius: 10,
                            borderTopRightRadius: 10,
                            overflow: "hidden",
                            justifyContent: "space-between",
                            minHeight: 40,
                            display: "flex",
                            flexDirection: "row"
                        }}
                    >
                        <View
                            style={{
                                justifyContent: "center"
                            }}
                        >
                            <Text
                                style={{
                                    fontSize: fontSizeTitle,
                                    fontFamily: ROOT_FONT,
                                    paddingVertical: 10
                                }}
                            >
                                {info.title}
                            </Text>
                        </View>
                        <Icon.Button
                            name={"menu-down"}
                            backgroundColor={"transparent"}
                            size={40}
                            iconStyle={{
                                color: "#4C8EF6",
                                marginRight: 0
                            }}
                            onPress={() => setExpansed(false)}
                        />

                    </View>
                    <View
                        style={{
                            paddingLeft: ".75rem",
                            backgroundColor: "#f8f9fa",
                            borderColor: "#4C8EF6",
                            borderWidth: 2,
                            borderTopWidth: 0,
                            borderBottomLeftRadius: 10,
                            borderBottomRightRadius: 10,
                            overflow: "hidden",
                            justifyContent: "space-between",
                            display: "flex"
                        }}
                    >
                        <Text
                            style={{
                                fontSize: fontSizeDescription,
                                fontFamily: ROOT_FONT,
                                paddingVertical: 10,
                                justifyContent: "space-between",
                            }}
                            adjustsFontSizeToFit={true}
                        >
                            {info.description}
                        </Text>
                    </View>
                </>
                :
                <>
                    <View
                        style={{
                            paddingLeft: ".75rem",
                            backgroundColor: "#A7D1F6",
                            borderColor: "#4C8EF6",
                            borderWidth: 2,
                            borderTopLeftRadius: 10,
                            borderTopRightRadius: 10,
                            borderBottomLeftRadius: 10,
                            borderBottomRightRadius: 10,
                            overflow: "hidden",
                            justifyContent: "space-between",
                            minHeight: 40,
                            display: "flex",
                            flexDirection: "row"
                        }}
                    >
                        <View
                            style={{
                                justifyContent: "center"
                            }}
                        >
                            <Text
                                style={{
                                    fontSize: fontSizeTitle,
                                    fontFamily: ROOT_FONT,
                                    paddingVertical: 10
                                }}
                            >
                                {info.title}
                            </Text>
                        </View>
                        <Icon.Button
                            name={"menu-up"}
                            backgroundColor={"transparent"}
                            size={40}
                            iconStyle={{
                                color: "#4C8EF6",
                                marginRight: 0
                            }}
                            onPress={() => setExpansed(true)}
                        />
                    </View>
                </>
            }

        </View>
    );
}

const generateFakeInfos = (numInfo) => {
    let infos = [];
    for (let id = 0; id < numInfo; id++) {
        infos.push({ key: generateRandomString(), id: id });
    }
    return infos;
}

const ProductScreen = ({ navigation, route }) => {
    const [idInfo, setIdInfo] = useState(0);
    const [imgId, setImgId] = useState(0);
    const [infos, setInfos] = useState(getInfos());
    const gotoScreen = (nameScreen) => {
        navigation.navigate(nameScreen);
    }

    const { params } = route;
    const { dimension } = params;
    let arrowLeftRightStyle = { position: 'absolute', right: 1, zIndex: 2, alignItems: "center", flexDirection: "column" };
    let arrowLeftName = 'menu-up';
    let arrowRightName = "menu-down";
    if (dimension.width < dimension.height) {
        arrowLeftRightStyle = { position: 'absolute', bottom: 9, zIndex: 2, alignItems: "center", flexDirection: "row" };
        arrowLeftName = "menu-left";
        arrowRightName = "menu-right";
    }

    let sizeProductImg = Math.min(dimension.width, dimension.height);

    let imgUri = getProductImg(imgId);
    let greenOpacity = 0.5;
    let orangeOpacity = 0.5;
    if (imgId < 3) {
        greenOpacity = 1.0;
    } else {
        orangeOpacity = 1.0;
    }

    let unitSize = (sizeProductImg / 9.0);
    let sizeHeightProductTop = Math.max(0, parseInt(dimension.height - 13 * unitSize));
    let sizeHeightProductBottom = dimension.height - sizeHeightProductTop - sizeProductImg;
    if (sizeHeightProductTop > 0.8 * sizeHeightProductBottom) {
        let sumEmptyTopInfo = sizeHeightProductTop + sizeHeightProductBottom;
        sizeHeightProductBottom = parseInt((sumEmptyTopInfo / 9) * 5);
        sizeHeightProductTop = sumEmptyTopInfo - sizeHeightProductBottom;
    }

    let styleProductImg = { position: 'absolute', top: sizeHeightProductTop, left: 0, zIndex: 1, alignItems: "flex-start", flexDirection: "column", width: sizeProductImg, height: sizeProductImg };

    // gia thiet dang xet cho truong hop dimension.width >= dimension.height
    let sizeWidthInfoPart = dimension.width - dimension.height; // co truong hop rat nho thi sao
    let sizeHeightInfoPart = sizeHeightProductBottom;
    let numberInfo = 3;
    // minh dang uu tien cho ti le 16:9 nen dieu nay neu su ra se xu ly o buoc sau
    // => toi uu hoa thoi gian
    let sizeWidthInfoForm = sizeWidthInfoPart - 60;
    let sizeHeightInfoForm = 100;
    if (dimension.width < dimension.height) {
        sizeWidthInfoPart = dimension.width;
        sizeWidthInfoForm = parseInt(dimension.width * 0.8);
        numberInfo = 1;
    }

    let screenFlexDirection = "row";
    if (dimension.width < dimension.height) {
        screenFlexDirection = "column";
    }

    return (
        <View style={{ width: "100%", height: "100%", backgroundColor: "white", justifyContent: "center", alignItems: "center" }}>
            {dimension.width < dimension.height ?
                <View style={{ width: sizeWidthInfoPart, backgroundColor: "transparent", position: "absolute", bottom: 0, alignItems: "center", zIndex: 3, flexDirection: "column-reverse" }}>
                    <View style={{ bottom: 50 }}>
                        <FlatList
                            data={generateFakeInfos(1)}
                            keyExtractor={(item) => item.key}
                            renderItem={({ item }) => <ItemInfo id={item.id} idInfo={idInfo} infos={infos} width={sizeWidthInfoForm} height={sizeHeightInfoForm} />}
                        // scrollEnabled={false}
                        // showsHorizontalScrollIndicator={false}
                        />
                    </View>
                    <View style={arrowLeftRightStyle}>
                        <View style={{ marginVertical: 1, marginHorizontal: 1, }}>
                            <Icon.Button
                                name={arrowLeftName}
                                backgroundColor={"transparent"}
                                size={40}
                                iconStyle={{
                                    color: "red",
                                    marginRight: 0
                                }}
                                onPress={() => setIdInfo(idInfo > 0 ? idInfo - 1 : idInfo)}
                            />
                        </View>

                        <View style={{ marginVertical: 1, marginHorizontal: 1, }}>
                            <Icon.Button
                                name={arrowRightName}
                                backgroundColor={"transparent"}
                                size={40}
                                iconStyle={{
                                    color: "red",
                                    marginRight: 0
                                }}
                                onPress={() => setIdInfo(idInfo < infos.length - numberInfo ? idInfo + 1 : idInfo)}
                            />
                        </View>
                    </View>
                </View>
                :
                <View style={{ width: sizeWidthInfoPart, height: dimension.height, backgroundColor: "transparent", position: "absolute", right: 0, justifyContent: "center" }}>
                    <View style={{ minHeight: 450 }}>
                        <FlatList
                            data={generateFakeInfos(3)}
                            keyExtractor={(item) => item.key}
                            renderItem={({ item }) => <ItemInfo id={item.id} idInfo={idInfo} infos={infos} width={sizeWidthInfoForm} height={sizeHeightInfoForm} />}
                        />
                    </View>

                    <View style={arrowLeftRightStyle}>
                        <View style={{ marginVertical: 1, marginHorizontal: 1, }}>
                            <Icon.Button
                                name={arrowLeftName}
                                backgroundColor={"transparent"}
                                size={40}
                                iconStyle={{
                                    color: "red",
                                    marginRight: 0
                                }}
                                onPress={() => setIdInfo(idInfo > 0 ? idInfo - 1 : idInfo)}
                            />
                        </View>

                        <View style={{ marginVertical: 1, marginHorizontal: 1, }}>
                            <Icon.Button
                                name={arrowRightName}
                                backgroundColor={"transparent"}
                                size={40}
                                iconStyle={{
                                    color: "red",
                                    marginRight: 0
                                }}
                                onPress={() => setIdInfo(idInfo < infos.length - numberInfo ? idInfo + 1 : idInfo)}
                            />
                        </View>
                    </View>
                </View>
            }
            <View style={styleProductImg}>
                <View style={{ flex: 2, width: "100%", flexDirection: "row", justifyContent: "center", alignItems: "flex-end" }}>
                    <View style={{ marginVertical: 5, marginHorizontal: 5, opacity: greenOpacity }}>
                        <Icon.Button
                            name={"cylinder"}
                            backgroundColor={"rgb(57,140,94)"}
                            size={50}
                            borderRadius={80}
                            iconStyle={{
                                marginTop: 0,
                                marginBottom: 0,
                                paddingTop: 0,
                                paddingBottom: 0,
                                marginRight: 0
                            }}
                            onPress={() => setImgId(getStartImgId4Product(0))}
                        />
                    </View>
                    <View style={{ marginVertical: 5, marginHorizontal: 5, opacity: orangeOpacity }}>
                        <Icon.Button
                            name={"cylinder"}
                            backgroundColor={"rgb(102,35,29)"}
                            size={50}
                            borderRadius={80}
                            iconStyle={{
                                marginTop: 0,
                                marginBottom: 0,
                                paddingTop: 0,
                                paddingBottom: 0,
                                marginRight: 0
                            }}
                            onPress={() => setImgId(getStartImgId4Product(1))}
                        />
                    </View>
                </View>
                <View style={{ flex: 8, width: "100%", flexDirection: "row", alignItems: "flex-start" }}>
                    <View style={{ flex: 1.5, height: "100%", alignItems: "center", justifyContent: "center" }}></View>
                    <View style={{ flex: 0.5, height: "100%", alignItems: "center", justifyContent: "center" }}>
                        <View style={{ marginVertical: 5, marginHorizontal: 5, opacity: 0.25 }}>
                            <Icon.Button
                                name={"menu-left"}
                                backgroundColor={"blue"}
                                size={20}
                                borderRadius={40}
                                iconStyle={{
                                    marginTop: 0,
                                    marginBottom: 0,
                                    paddingTop: 0,
                                    paddingBottom: 0,
                                    marginRight: 0
                                }}
                                onPress={() => setImgId(getPrevImgId(imgId))}
                            />
                        </View>
                    </View>
                    <View style={{ flex: 6, height: "100%", alignItems: "center", justifyContent: "center" }}>
                        <Image
                            style={{ width: "90%", height: "90%", resizeMode: "contain", alignItems: "center", justifyContent: "center" }}
                            source={{ uri: imgUri }}
                        >
                        </Image>
                    </View>
                    <View style={{ flex: 0.5, height: "100%", alignItems: "center", justifyContent: "center" }}>
                        <View style={{ marginVertical: 5, marginHorizontal: 5, opacity: 0.25 }}>
                            <Icon.Button
                                name={"menu-right"}
                                backgroundColor={"blue"}
                                size={20}
                                borderRadius={40}
                                iconStyle={{
                                    marginTop: 0,
                                    marginBottom: 0,
                                    paddingTop: 0,
                                    paddingBottom: 0,
                                    marginRight: 0
                                }}
                                onPress={() => setImgId(getNextImgId(imgId))}
                            />
                        </View>
                    </View>
                    <View style={{ flex: 1.5, height: "100%", alignItems: "center", justifyContent: "center" }}></View>
                </View>
            </View>
            <View style={{ position: 'absolute', top: 5, left: 5, zIndex: 2, alignItems: "flex-start", flexDirection: screenFlexDirection }}>
                <View style={{ marginVertical: 5, marginHorizontal: 5, }}>
                    <Icon.Button
                        name={'home'}
                        backgroundColor={"#4C8EF6"}
                        size={32}
                        onPress={() => gotoScreen(screens.main)}
                    />
                </View>
                <View style={{ marginVertical: 5, marginHorizontal: 5, opacity: 0.25 }}>
                    <Icon.Button
                        name={'cart-outline'}
                        backgroundColor={"#4C8EF6"}
                        size={32}
                    // onPress={() => gotoScreen(screens.product)}
                    />
                </View>
                <View style={{ marginVertical: 5, marginHorizontal: 5, }}>
                    <Icon.Button
                        name={'form-select'}
                        backgroundColor={"#4C8EF6"}
                        size={32}
                        onPress={() => gotoScreen(screens.form)}
                    />
                </View>
            </View>

        </View>
    );
}
export default ProductScreen;