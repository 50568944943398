const patternForPhone = /^[0]?[123456789]\d{8,12}$/;
const patternForYear = /^\d{4}$/;

export const isCorrectName=(name)=>{
    if(name == null || name.trim().length == 0) return false;
    return true;
}

export const isCorrectPhone=(phone)=>{
    if(phone == null || phone.trim().length == 0) return false;
    return patternForPhone.test(phone);
}

export const isCorrectYear=(year)=>{
    if(year == null || year.trim().length == 0) return false;
    return patternForYear.test(year);
}