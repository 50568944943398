import { getAuth } from "firebase/auth";
import { getDatabase, ref, onValue, child, off } from "firebase/database";
import axios from "axios";

var chatServer = '';
export const getChatServer = () => {
    return chatServer;
}

var firstOnValueCharSever = true;
export const listenerChatServer = () => {
    if (firstOnValueCharSever) {
        firstOnValueCharSever = false;
        onValue(child(ref(getDatabase()), "famoby"), (snapshot) => {
            if (snapshot.exists()) {
                chatServer = snapshot.val();
            }
        });
    }
}

export const offListenerChatServer = () => {
    if (!firstOnValueCharSever) {
        off(child(ref(getDatabase()), "famoby"));
        firstOnValueCharSever = true;
    }
}

export const uploadForm = async(params)=>{
    let response = await axios({
        method: 'post',
        url: chatServer,
        data: params,
        headers: {
            'Access-Control-Allow-Origin': '*',
        },
        withCredentials: true
    });
    let data = response.data;
    return data.code;
}