var img01 = require("../../assets/01.jpg");
var img02 = require("../../assets/02.jpg");
var img03 = require("../../assets/03.jpg");
var img04 = require("../../assets/04.jpg");
var img05 = require("../../assets/05.png");
var img06 = require("../../assets/06.png");

// index: trong mang co n phan tu: n = imgs.length thi cac chi so duoc danh so tu 0, 1, 2, ...., n - 1
var imgs = [img01, img02, img03, img04];

var mainImgs = [img01, img02, img03, img04];

var widthMainImgs = [img01, img02, img03];
var heightMainImgs = [img04, img05, img06];

export const getImgUriMain=(imdId, dimension)=>{
    if(dimension.width > dimension.height){
        return widthMainImgs[imdId % mainImgs.length];
    }else{
        return heightMainImgs[imdId % mainImgs.length];
    }
}

export function getProductImg(id){
    let realId = (id % productImgs.length + productImgs.length) % productImgs.length;
    return productImgs[realId];
}
export function getNextImgId(id){
    id = id + 1;
    if(id >= productImgs.length){
        id = 0;
    }
    return id;
}

export function getPrevImgId(id){
    id = id - 1;
    if(id< 0){
        id = productImgs.length - 1;
    }
    return id;
}

export function getStartImgId4Product(productId){
    if(productId == 0) return 0;
    return 3;
}

// Math la thu vien co san
export function randomImage(){
    var numberImage = imgs.length;
    let id = Math.floor(Math.random() * numberImage);
    return imgs[id % numberImage];
}

var productImg0101 = require("../../assets/products/p01_01.png");
var productImg0102 = require("../../assets/products/p01_02.png");
var productImg0103 = require("../../assets/products/p01_03.png");
var productImg0201 = require("../../assets/products/p02_01.png");
var productImg0202 = require("../../assets/products/p02_02.png");
var productImg0203 = require("../../assets/products/p02_03.png");

var productImgs = [productImg0101, productImg0102, productImg0103, productImg0201, productImg0202, productImg0203];

var info00 = {title: "THÀNH PHẦN DINH DƯỠNG", description: "Sữa bột, Bột béo thực vật, Đường lactose, Bột yến mạch, Vitamin (Vitamin A), Vitamin D3, Vitamin E, Vitamin K1, Vitatmin K2, Biotin, Các Vitatmin nhóm B (B1, B2, B3, B5, B6, B9, B12). Khoáng chất (Canxi, Phospho, Sắt, Kẽm, Magie, Đồng, Selen, Natri, Kali, Manganm I-ốt). E'FLHMO, Omega 3, Omega 6, Lysine, Choline, Taurine, Collagen tripeptide, Chất sơ hòa tan (NeoGoS P70, FOS). Lactase, Lợi khuẩn (Bacillus subtilis, Loatobacillus sporogenes), Enzyme (Protase, Lactase), Immune. Hương liệu tổng hợp dùng trong thực phẩm (Hương Vani)", id: "00"};
var info01 = {title: "CÔNG DỤNG", description: "FAMOBY thay thế bữa phụ hoặc bổ sung cho chế độ ăn hàng ngày thêm các vi chất dinh dưỡng. Bổ sung dinh dưỡng cùng các vitamin và khoáng chất cần thiết, cung cấp năng lượng cho cơ thể.", id: "01"};
var info02 = {title: "BẢO QUẢN", description: "Bảo quản thực phẩm nơi thoáng mát, tránh ánh nắng trực tiếp chiếu vào sản phẩm và không bảo quản sản phẩm trong tủ lạnh.\nLon đã mở phải được đậy nắp kín và sử dụng tốt nhất trong vòng 30 ngày.", id: "02"};
var info03 = {title: "ĐỐI TƯỢNG SỬ DỤNG", description: "Dành cho người 10 tuổi trở lên", id: "03"};
var info04 = {title: "THỜI HẠN SỬ DỤNG", description: "24 tháng kể từ ngày sản xuất", id: "04"};

var infos = [info00, info01, info02, info03, info04];

export const getInfos=()=>{
    return infos;
}

export const getSubInfos=(idInfo, numInfo)=>{
    let arr = [];
    for(let i = 0; i < numInfo; i++){
        let id = (idInfo + i) % infos.length;
        arr.push(infos[id]);
    }
    return arr;
}

export const getNextIdInfo = (idInfo)=>{
    return (idInfo + 1) % infos.length;
}

export const getPrevIdInfo = (idInfo)=>{
    return (idInfo - 1 + infos.length) % infos.length;
}
