import { Button, Pressable, Text, TextInput } from "react-native";
import { View } from "react-native";
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import { screens } from "../utils/ScreenUtils";
import { useState } from "react";
import { Image } from "react-native-web";
import { TouchableOpacity } from "react-native";
import { uploadForm } from "../utils/SeverFuncs";
import { isCorrectYear, isCorrectName, isCorrectPhone } from "../utils/CustomerUtils";
import { callFormDimension } from "../utils/ComponentUtils";
import { OBJECT_LOGO } from "../utils/Constants";

const uriLogo = require("../../assets/logo.png");

const FormScreen = ({ navigation, route }) => {
    const [phone, setPhone] = useState("");
    const [name, setName] = useState("");
    const [year, setYear] = useState("");
    const [infoText, setInfoText] = useState("Xin chào quý khách!");
    const [textColor, setTextColor] = useState("black");
    const { params } = route;
    const { dimension } = params;
    const gotoScreen = (nameScreen) => {
        navigation.navigate(nameScreen);
    }
    const { formWidth, formHeight } = callFormDimension(dimension);
    let logoSize = formWidth / 3;

    const upLoadToDB = async () => {
        if (phone == '' || !isCorrectPhone(phone)) {
            setTextColor("red");
            setInfoText("Số điện thoại chưa đúng!");
            return;
        }
        if (name == '' || !isCorrectName(name)) {
            setTextColor("red");
            setInfoText("Tên chưa đúng!");
            return;
        }
        if (year == '' || !isCorrectYear(year)) {
            setTextColor("red");
            setInfoText("Năm sinh chưa đúng!");
            return;
        }
        let params = {};
        params['phone'] = phone.trim();
        params['name'] = name.trim();
        params['year'] = year.trim();
        let code = await uploadForm(params);
        if (code == 0) {
            setTextColor("black");
            setInfoText("Xin cảm ơn quý khách!");
            setPhone("");
            setName("");
            setYear("");
        } else {
            setTextColor("red");
            setInfoText("Xin lỗi, có lỗi đường truyển!");
        }
    }

    return (
        <View style={{ width: "100%", height: "100%", backgroundColor: "white", justifyContent: "center", alignItems: "center", flexDirection: "column", alignItems: "center" }}>
            <View style={{ flexDirection: "column", width: formWidth, height: formHeight, flexDirection: "column", alignItems: "center", justifyContent: "center"}}>
                <View style={{ alignItems: "center", justifyContent: "center", height: logoSize, width: formWidth}}>
                    <Image
                        style={{ width: logoSize, height: logoSize, resizeMode: "contain", alignItems: "center", justifyContent: "center" }}
                        source={OBJECT_LOGO}
                    >
                    </Image>
                </View>
                <View style={{ alignItems: "center", margin: 50 }}>
                    <Text style={{ fontSize: "20px", color: textColor }}>
                        {infoText}
                    </Text>
                </View>
                <View style={{ alignItems: "center", marginBottom: 10 }}>
                    <TextInput
                        style={{
                            color: "#525252",
                            textAlign: "center",
                            fontStyle: "italic",
                            borderRadius: 80,
                            height: 40,
                            width: formWidth,
                            margin: 12,
                            borderWidth: 2,
                            padding: 10,
                            backgroundColor: "#A7D1F6",
                            borderColor: "#4C8EF6",
                        }}
                        onChangeText={setPhone}
                        value={phone}
                        placeholder={"SỐ ĐIỆN THOẠI"}
                        placeholderTextColor={""}
                        keyboardType={"phone-pad"}
                    />
                </View>
                <View style={{ alignItems: "center", marginBottom: 10 }}>
                    <TextInput
                        style={{
                            color: "#525252",
                            textAlign: "center",
                            fontStyle: "italic",
                            borderRadius: 80,
                            height: 40,
                            width: formWidth,
                            margin: 12,
                            borderWidth: 2,
                            padding: 10,
                            backgroundColor: "#A7D1F6",
                            borderColor: "#4C8EF6"
                        }}
                        onChangeText={setName}
                        value={name}
                        placeholder={"HỌ VÀ TÊN"}
                    />
                </View>
                <View style={{ alignItems: "center", marginBottom: 10 }}>
                    <TextInput
                        style={{
                            color: "#525252",
                            textAlign: "center",
                            fontStyle: "italic",
                            borderRadius: 80,
                            height: 40,
                            width: formWidth,
                            margin: 12,
                            borderWidth: 2,
                            padding: 10,
                            backgroundColor: "#A7D1F6",
                            borderColor: "#4C8EF6"
                        }}
                        onChangeText={setYear}
                        value={year}
                        placeholder={"NĂM SINH"}
                        keyboardType={"numeric"}
                    />
                </View>
                <View style={{ alignItems: "center", margin: 10 }}>
                    <TouchableOpacity
                        style={{ alignItems: "center", justifyContent: "center", backgroundColor: "#538EF6", padding: 10, width: logoSize, borderRadius: 80 }}
                        onPress={upLoadToDB}
                    >
                        <Text >Đăng ký</Text>
                    </TouchableOpacity>
                </View>
            </View>


            <View style={{ position: 'absolute', top: 5, left: 5, zIndex: 2, alignItems: "flex-start" }}>
                <View style={{ marginVertical: 5, marginHorizontal: 5, }}>
                    <Icon.Button
                        name={'home'}
                        backgroundColor={"#4C8EF6"}
                        size={32}
                        onPress={() => gotoScreen(screens.main)}
                    />
                </View>
                <View style={{ marginVertical: 5, marginHorizontal: 5, }}>
                    <Icon.Button
                        name={'cart-outline'}
                        backgroundColor={"#4C8EF6"}
                        size={32}
                        onPress={() => gotoScreen(screens.product)}
                    />
                </View>
                <View style={{ marginVertical: 5, marginHorizontal: 5, opacity: 0.25 }}>
                    <Icon.Button
                        name={'form-select'}
                        backgroundColor={"#4C8EF6"}
                        size={32}
                    // onPress={() => gotoScreen(screens.form)}
                    />
                </View>
            </View>
        </View>
    );
}
export default FormScreen;