import { Fragment, useEffect, useLayoutEffect, useState } from "react";
import generateHexSVG from "../generates/HexSvg";

const elevationStyleHover = (elevation) => {
    return {
        cursor: 'pointer',
        transform: `translateY(${elevation/2}px)`
    }
}

const elevationStyleActive = (elevation) => {
    return {
        cursor: 'pointer',
        transition: 'all 0.1s ease',
        transform: `translateY(${elevation}px)`
    }
}

var propsDefault = {
    sideLength: 100,
    borderRadius: 12,
    fill: "white",
    stroke: "#bbb",
    strokeWidth: 0,
    elevation: 12,
    shadow: "#e2e2e2",
    img: '',
    text: '',
    textStyle: {},
    styles: {
        normal: {},
        hover: {},
        active: {}
    },
    href: null,
    target: null,
    onPress: () => { }
};


const Hexagon = ({ elevation, stroke, strokeWidth, styles, sideLength, borderRadius, shadow, img, text, textStyle, href, target, onPress, fill, hoverColor, activeColor}) => {
    if (!strokeWidth) strokeWidth = propsDefault.strokeWidth;
    if (!stroke) stroke = propsDefault.stroke;
    if (!fill) fill = propsDefault.fill;
    if (!hoverColor) hoverColor = fill;
    if (!activeColor) activeColor = fill;
    if(!elevation) elevation= propsDefault.elevation;
    if(!href) href = propsDefault.href;
    if(!shadow) shadow = propsDefault.shadow;
    if(!img) img = propsDefault.img;
    if(!styles) styles = propsDefault.styles;
    if(!sideLength) sideLength = propsDefault.sideLength;
    if(!text) text = propsDefault.text;
    if(!textStyle) textStyle = propsDefault.textStyle;
    if(!target) target = propsDefault.target;
    if(!onPress) onPress = propsDefault.onPress;

    // console.log("strokeWidth: " + strokeWidth);
    // console.log("stroke: " + stroke);
    // console.log("fill: " + fill);
    // console.log("elevation: " + elevation);
    // console.log("href: " + href);
    // console.log("shadow: " + shadow);
    // console.log("img: " + img);
    // console.log("styles: " + JSON.stringify(styles));
    // console.log("sideLength: " + sideLength);
    // console.log("borderRadius: " + borderRadius);
    // console.log("text: " + text);
    // console.log("textStyle: " + JSON.stringify(textStyle));
    // console.log("target: " + target);
    // console.log("onClick: " + onClick);

    var thHexagonStyleBase = {
        userSelect: 'none',
        stroke,
        strokeWidth: `${strokeWidth}px`,
        transition: 'all 0.2s ease',
    }
    const thHexagonStyleNormal = Object.assign({}, thHexagonStyleBase, styles.normal, {fill: fill});
    const thHexagonStyleHover = Object.assign({}, thHexagonStyleBase, elevation ? elevationStyleHover(elevation) : {}, styles.hover, {fill: hoverColor});
    const thHexagonStyleActive = Object.assign({}, thHexagonStyleBase, elevation ? elevationStyleActive(elevation) : {}, styles.active, {fill: activeColor});
    const [thHexagonStyle, setThHexagonStyle] = useState(thHexagonStyleNormal);
    let width = Math.sqrt(3) * sideLength;
    let height = 2 * sideLength + elevation;
    let fontSizeOffset = (textStyle && textStyle.fontSize) ? 0.3 * parseInt(textStyle.fontSize) : 0;
    // console.log("width = " + width + ", height = " + height);
    // console.log("fontSizeOffset = " + fontSizeOffset);
    // let dPath = generateHexSVG(sideLength, borderRadius);
    // console.log("dPath = " + dPath);
    const [oldFill, setOldFill] = useState("");
    const hexagon = (
        <Fragment>
            {/* <path fill={fill} d={generateHexSVG(sideLength, borderRadius)} /> */}
            <path d={generateHexSVG(sideLength, borderRadius)} />
            <image href={img} width={0.7 * width} height={0.7 * height} x={0.15 * width} y={0.12 * height}></image>
            <text fill={"#bbb"} strokeWidth={0} style={textStyle}>
                <tspan x={width / 2} y={height / 2 + fontSizeOffset} textAnchor={"middle"}>
                    {text}
                </tspan>
            </text>
        </Fragment>
    );

    const hexagonFill = (
        <Fragment>
            <path fill={fill} d={generateHexSVG(sideLength, borderRadius)} />
            {/* <path d={generateHexSVG(sideLength, borderRadius)} /> */}
            <image href={img} width={0.7 * width} height={0.7 * height} x={0.15 * width} y={0.12 * height}></image>
            <text fill={"#bbb"} strokeWidth={0} style={textStyle}>
                <tspan x={width / 2} y={height / 2 + fontSizeOffset} textAnchor={"middle"}>
                    {text}
                </tspan>
            </text>
        </Fragment>
    );
    const [isOver, setIsOver] = useState(false);

    useEffect(() => {
        // console.log("useEffect");
    }, []);
    useLayoutEffect(() => {
        // console.log("useLayoutEffect");
    }, []);
    return (
        <svg
            viewBox={`0 0 ${width} ${height}`}
            width={width}
            height={height}
        >   
            <svg y={elevation}><path fill={shadow} d={generateHexSVG(sideLength, borderRadius)} /></svg>
            
            <g
                style={thHexagonStyle}
                onMouseOver={()=>{
                    setThHexagonStyle(thHexagonStyleHover);
                    setIsOver(true);
                }}
                onMouseLeave={()=>{
                    setThHexagonStyle(thHexagonStyleNormal);
                    setIsOver(false);
                }}
                onMouseDown={()=>{
                    setThHexagonStyle(thHexagonStyleActive);
                }}
                onMouseUp={()=>{
                    setThHexagonStyle(thHexagonStyleHover);
                }}
                onClick={onPress}
            >
                {/* {!href ? hexagon : <a href={href} target={target || '_blank'}>{hexagon}</a>} */}
                {isOver?hexagon: hexagonFill}
            </g>
        </svg>
    );
}
export default Hexagon;