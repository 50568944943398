import { Text } from "react-native";
import { View } from "react-native";

const WellcomeScreen = ()=>{
    return(
        <View style={{ width: "100%", height: "100%", backgroundColor: "white", justifyContent: "center", alignItems: "center"}}>
            <Text>
                Wellcome to Famoby
            </Text>
        </View>
    );
}
export default WellcomeScreen;