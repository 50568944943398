import { NavigationContainer } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import React, { useEffect, useState } from 'react';
import { Dimensions, Platform } from 'react-native';
import { Provider} from 'react-redux';
import { useFonts } from 'expo-font';

import { tabStore } from './src/states/store';
import { initializeApp } from 'firebase/app';
import firebase from 'firebase/compat/app';
import { firebaseConfig } from './config';
import { screens } from './src/utils/ScreenUtils';
import WellcomeScreen from './src/screens/WellcomeScreen';
import MainScreen from './src/screens/MainScreen';
import ProductScreen from './src/screens/ProductScreen';
import FormScreen from './src/screens/FormScreen';

import * as WebBrowser from 'expo-web-browser';
import { listenerChatServer, offListenerChatServer } from './src/utils/SeverFuncs';

try {
  if (Platform.OS === 'web') {
    firebase.initializeApp(firebaseConfig);
  } else {
    initializeApp(firebaseConfig);
  }
} catch (error) {
  console.log("Initializing error ", error);
}

WebBrowser.maybeCompleteAuthSession();
const Stack = createNativeStackNavigator();

export const UserContext = React.createContext();

const Body = () => {
  const [dimension, setDimension] = useState(Dimensions.get('window'));
  useEffect(() => {
    const subscription = Dimensions.addEventListener(
      'change',
      ({ window }) => {
        setDimension(window);
      }
    );
    return () => {
      subscription.remove();
    }
  }, []);
  return (
    <NavigationContainer>
      <Stack.Navigator
        screenOptions={{
          headerShown: false
        }}
        initialRouteName={screens.form}
      >
        <Stack.Screen
          name={screens.wellcome}
          component={WellcomeScreen}
          options={{ title: "Wellcome" }}
          initialParams={{ "dimension": dimension }}
        />
        <Stack.Screen
          name={screens.main}
          component={MainScreen}
          options={{ title: "Home" }}
          initialParams={{ "dimension": dimension }}
        />
        <Stack.Screen
          name={screens.product}
          component={ProductScreen}
          options={{ title: "Product" }}
          initialParams={{ "dimension": dimension }}
        />
        <Stack.Screen
          name={screens.form}
          component={FormScreen}
          options={{ title: "Form" }}
          initialParams={{ "dimension": dimension }}
        />
      </Stack.Navigator>
    </NavigationContainer>
  )
}

export default function App() {
  const [fontsLoaded] = useFonts({
    'Arial': require('./assets/fonts/Arial.ttf'),
    'TimesNewRoman': require('./assets/fonts/TimesNewRoman.ttf')
  });
  useEffect(()=>{
    listenerChatServer();
    return ()=>{
      offListenerChatServer();
    };
  },[]);
  return (
    <Provider store={tabStore}>
      <Body />
    </Provider>
  );
}

