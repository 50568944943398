import { Image, Text } from "react-native";
import { View } from "react-native";
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import { screens } from "../utils/ScreenUtils";
import { useState } from "react";
import { getImgUriMain } from "../data/manualData";
import Hexagon from "../components/Hexagon";


const MainScreen = ({ navigation, route }) => {
    const [imgId, setImgId] = useState(0);
    const gotoScreen = (nameScreen) => {
        navigation.navigate(nameScreen);
    }
    const { params } = route;
    const { dimension } = params;
    // console.log(dimension);
    let arrowLeftRightStyle = { position: 'absolute', bottom: 9, zIndex: 1, alignItems: "center", flexDirection: "row" };
    if (dimension.width < dimension.height) {
        arrowLeftRightStyle = { position: 'absolute', bottom: 9, right: 5, zIndex: 1, alignItems: "flex-start", flexDirection: "row" };
    }
    let screenFlexDirection = "row";
    if (dimension.width < dimension.height) {
        screenFlexDirection = "column";
    }
    return (
        <View style={{ width: "100%", height: "100%", backgroundColor: "white", justifyContent: "center", alignItems: "center" }}>
            <Image
                style={{ width: "100%", height: "100%", resizeMode: "contain", alignItems: "center", justifyContent: "center" }}
                source={{ uri: getImgUriMain(imgId, dimension) }}
            >
            </Image>
            <View style={{ position: 'absolute', top: 5, left: 5, zIndex: 2, alignItems: "flex-start", flexDirection: screenFlexDirection }}>
                <View style={{ marginVertical: 5, marginHorizontal: 5 , opacity: 0.25}}>
                    <Icon.Button
                        name={'home'}
                        backgroundColor={"#4C8EF6"}
                        size={32}
                        // onPress={() => gotoScreen(screens.main)}
                    />
                </View>
                <View style={{ marginVertical: 5, marginHorizontal: 5, }}>
                    <Icon.Button
                        name={'cart-outline'}
                        backgroundColor={"#4C8EF6"}
                        size={32}
                        onPress={() => gotoScreen(screens.product)}
                    />
                </View>
                <View style={{ marginVertical: 5, marginHorizontal: 5, }}>
                    <Icon.Button
                        name={'form-select'}
                        backgroundColor={"#4C8EF6"}
                        size={32}
                        onPress={() => gotoScreen(screens.form)}
                    />
                </View>
            </View>
            <View style={{ position: 'absolute', top: 100, right: 5, zIndex: 2, alignItems: "flex-start", flexDirection: "column" }}>
                <View style={{ marginVertical: 5, marginHorizontal: 5, }}>
                    <Hexagon
                        fill={imgId == 0 ? "red" : "orange"}
                        hoverColor={"gray"}
                        activeColor={"blue"}
                        sideLength={20}
                        borderRadius={4}
                        shadow="#e2e2e2"
                        href="https://reactjs.org/"
                        target="_blank" //open in new tab
                        onPress={() => setImgId(0)}
                    ></Hexagon>
                </View>
                <View style={{ marginVertical: 5, marginHorizontal: 5, }}>
                    <Hexagon
                        fill={imgId == 1 ? "red" : "orange"}
                        hoverColor={"gray"}
                        activeColor={"blue"}
                        sideLength={20}
                        borderRadius={4}
                        shadow="#e2e2e2"
                        href="https://reactjs.org/"
                        target="_blank"
                        onPress={() => setImgId(1)}
                    ></Hexagon>
                </View>
                <View style={{ marginVertical: 5, marginHorizontal: 5, }}>
                    <Hexagon
                        fill={imgId == 2 ? "red" : "orange"}
                        hoverColor={"gray"}
                        activeColor={"blue"}
                        sideLength={20}
                        borderRadius={4}
                        shadow="#e2e2e2"
                        href="https://reactjs.org/"
                        target="_blank"
                        onPress={() => setImgId(2)}
                    ></Hexagon>
                </View>
            </View>

            {/* <View style={arrowLeftRightStyle}>
                <View style={{ marginVertical: 10, marginHorizontal: 10, }}>
                    <Icon.Button
                        name={'menu-left'}
                        backgroundColor={"transparent"}
                        size={50}
                        iconStyle={{ color: "red" }}
                    />
                </View>
                <View style={{ marginVertical: 10, marginHorizontal: 10, }}>
                    <Icon.Button
                        name={'menu-right'}
                        backgroundColor={"transparent"}
                        size={50}
                        iconStyle={{ color: "red" }}
                    />
                </View>
            </View> */}
        </View>
    );
}
export default MainScreen;